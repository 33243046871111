<template>
  <div>
    <div class="interactive-page">
      <div class="interactive-page__header">
        <div class="input-block__actions" v-if="showHowItWorks === false">
          <div class="input-block__actions-wrapper">
            <div class="input-block__actions-general">
              <v-btn
                :disabled="
                  textarea.length > textareaLimit || textarea.length === 0
                "
                class="actions-encode"
                @click="encode"
                v-if="encodeStatus === 'ready' || encodeStatus === 'edit'"
              >
                <div class="icon" v-if="!_isMobile">
                  <div class="encode-icon"></div>
                </div>
                encode
              </v-btn>
              <v-btn
                class="actions-proseccing"
                v-else-if="encodeStatus === 'proseccing'"
              >
                <div class="icon" v-if="!_isMobile">
                  <div class="proseccing-icon"></div>
                </div>
                processing
              </v-btn>

              <v-btn
                :class="{ enabled: encodeStatus === 'finished' }"
                v-else-if="
                  encodeStatus === 'finished' && encodeStatus !== 'edit'
                "
                @click="edit"
              >
                <div class="icon">
                  <div class="edit-icon"></div>
                </div>
                edit text
              </v-btn>
              <v-btn
                :class="[
                  'actions-cancel',
                  { enabled: encodeStatus === 'finished' }
                ]"
                v-if="encodeStatus !== 'ready' && encodeStatus === 'edit'"
                @click="cancel"
              >
                {{ _isMobile ? 'X' : 'Cancel' }}
              </v-btn>
            </div>

            <div class="dialog-box-wrapper">
              <v-btn
                :class="['actions-find', 'enabled']"
                @click="
                  showExtraMenu === false ? (showHowItWorks = true) : null
                "
              >
                <div class="icon">
                  <div class="i-icon"></div>
                </div>
              </v-btn>
              <v-btn
                :class="[
                  'actions-find',
                  { enabled: encodeStatus === 'finished' }
                ]"
                :disabled="encodeStatus !== 'finished'"
                @click="showExtraMenu = true"
              >
                <div class="icon">
                  <div class="more-icon"></div>
                </div>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!showHowItWorks && !showExtraMenu"
        :class="['interactive-page__container', { mobile: _isMobile }]"
      >
        <div class="input-block">
          <div class="input-block__title">input >> text</div>
          <v-textarea
            v-if="encodeStatus !== 'finished'"
            :readonly="
              encodeStatus === 'finished' || encodeStatus === 'proseccing'
            "
            class="input-block__textarea"
            placeholder="Your text here"
            v-model="textarea"
            :counter="textareaLimit"
            rows="2"
            :rules="[
              v =>
                (v || '').length < textareaLimit ||
                `Text must be ${textareaLimit} characters or less.`
            ]"
          ></v-textarea>
          <div
            v-else-if="encodeStatus === 'finished'"
            :class="[
              'input-block__result',
              { 'container x-scroll x-mandatory mobile': _isMobile }
            ]"
            id="input-block__result"
            ref="contentInput"
          >
            <template v-if="_isMobile">
              <!-- <div class="d-flex">
                <div class="container x-scroll x-mandatory"> -->
              <!-- <div class="content mobile" ref="contentBox"> -->
              <ul :class="['content line', { mobile: _isMobile }]">
                <li
                  :key="`${obj.sequence}-${obj.input}-${obj.end}`"
                  class="symbol-wrapper"
                  v-for="obj of mapping"
                  :id="`s-${obj.sequence}-${obj.start}-${obj.end}`"
                >
                  <div
                    :class="[
                      'symbol-box',
                      {
                        active: selectedSumbol.find(
                          item =>
                            obj.sequence === item.sequence &&
                            obj.start === item.start
                        )
                      }
                    ]"
                  >
                    <div
                      :class="['symbol']"
                      @click="selectSumbol(obj)"
                      @mouseover="selectSumbol(obj)"
                    >
                      <template v-if="obj.input === '\\n'">
                        <v-tooltip
                          content-class="line-break__tooltip"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            &nbsp;
                          </span>
                          </template>
                          <span>line break</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        {{ obj.input === ' ' ? '      ' : obj.input }}
                      </template>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- </div> -->
              <!-- </div>
              </div> -->
              <!-- <div class="d-flex">
                <div>
                <ul :class="['line', { mobile: _isMobile }]">
                  <li
                    :key="`${obj.sequence}-${obj.input}-${obj.end}`"
                    class="symbol-wrapper"
                    v-for="obj of mapping"
                    :id="`s-${obj.sequence}-${obj.start}-${obj.end}`"
                  >
                    <div
                      :class="[
                        'symbol-box',
                        {
                          active: selectedSumbol.find(
                            item =>
                              obj.sequence === item.sequence &&
                              obj.start === item.start
                          )
                        }
                      ]"
                    >
                      <div
                        :class="['symbol']"
                        @click="selectSumbol(obj)"
                        @mouseover="selectSumbol(obj)"
                      >
                        {{ obj.input === ' ' ? '      ' : obj.input }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              </div> -->
              <!-- <v-textarea
                v-if="encodeStatus === 'finished'"
                :readonly="
                  encodeStatus === 'finished' || encodeStatus === 'proseccing'
                "
                class="input-block__textarea"
                placeholder="Your text here"
                v-model="textarea"
                :counter="textareaLimit"
                rows="2"
                :rules="[
                  v =>
                    (v || '').length < textareaLimit ||
                    `Text must be ${textareaLimit} characters or less.`
                ]"
              /> -->
            </template>
            <div class="line" v-else>
              <div
                v-for="(obj, number) of mapping"
                :key="number"
                class="symbol-wrapper"
                :class="{
                  'line-break': obj.input === '\\n'
                }"
                :style="{
                  'width': obj.input === '\\n'
                  && `calc(100% - ${quantityOfSymbolsBeforeBreak(obj)} * 38px)`
                  || '38px'
                }"
              >
                <div
                  :class="[
                    'symbol-box',
                    {
                      active: selectedSumbol.find(
                        item =>
                          obj.sequence === item.sequence &&
                          obj.start === item.start
                      )
                    }
                  ]"
                >
                  <div
                    :class="['symbol']"
                    @click="selectSumbol(obj)"
                    @mouseover="selectSumbol(obj)"
                  >
                    <template v-if="obj.input === '\\n'">
                      <v-tooltip
                        content-class="line-break__tooltip"
                        top
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            &nbsp;
                          </span>
                        </template>
                        <span>line break</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      {{ obj.input === ' ' ? '      ' : obj.input }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="input-block__preview"
            v-if="encodeStatus === 'finished' && _isMobile"
          >
            <pre>{{ textarea }}</pre>
          </div>
        </div>
        <div class="output-block">
          <div class="output-block__title">output >> DNA</div>
          <OutputResult
            :encodeResult="encodeResult"
            :rows="rows"
            @selectDNASeq="selectDNASeq"
            :selectedSumbol="selectedSumbol"
            ref="outputResult"
            :mapping="mapping"
          />
        </div>
      </div>
      <div v-else-if="showHowItWorks">
        <HowItWorks @close="showHowItWorks = false" />
      </div>
      <div v-else-if="showExtraMenu">
        <ExtraMenu
          @close="showExtraMenu = false"
          :shareLink="`${_location}/interactive?job_id=${data.id}`"
          :input="
            `text (${textarea.length} characters) ${textarea.length} bytes`
          "
          :output="`${amountSeq} DNA Sequences`"
          :outputFileUrl="data.output_file_url || ''"
          :price="data.price || '$0'"
        />
      </div>
    </div>
    <div v-if="!showHowItWorks" @click="back()"><BackButton /></div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton';
// import DialogBox from '@/components/DialogBox';
import HowItWorks from '@/components/HowItWorks';
import ExtraMenu from '@/components/ExtraMenu';
import OutputResult from '@/components/interactive/OutputResult';
import { bus } from '../bus';
import $ from 'jquery';
import { isMobile } from 'mobile-device-detect';
import { mapActions } from 'vuex';
// var scrollToAnchor = require('scroll-to-anchor');
import VueScrollTo from 'vue-scrollto';

const optionsOutput = {
  container: '#output-block__result',
  duration: 0,
  easing: 'ease',
  offset: -88,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: true,
  y: true
};

const optionsInput = {
  container: '#input-block__result',
  duration: 0,
  easing: 'ease',
  offset: -88,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: true,
  y: true
};

const VUE_APP_URL = process.env.VUE_APP_URL || '';

export default {
  name: 'Interactive',
  components: {
    BackButton,
    // DialogBox,
    OutputResult,
    HowItWorks,
    ExtraMenu
  },
  data() {
    return {
      textarea: '',
      encodedTextarea: '',
      encodeStatus: 'ready',
      encodeResult: [],
      mapping: [],
      selectedSumbol: [],
      selectedDNASeq: {},
      data: {},
      connect: null,
      textareaLimit: 100,
      rows: 0,
      showHowItWorks: false,
      showExtraMenu: false,
      amountSeq: 0,
      visibleItems: [],
      visibleItemsOutput: []
    };
  },
  computed: {
    _isMobile() {
      return isMobile;
    },
    _location() {
      return window.location.origin;
    }
  },
  methods: {
    ...mapActions(['setProcessType']),
    edit() {
      this.encodeStatus = 'edit';
    },
    cancel() {
      this.$set(this, 'textarea', this.encodedTextarea);
      this.encodeStatus = 'finished';
    },
    encode() {
      console.log('this.', this.textarea);
      this.$set(this, 'encodedTextarea', this.textarea);
      this.encodeStatus = 'proseccing';
      this.connect = this.$webSocketsConnect(
        '/api/v1/jobs/ws/demo-encode-file'
      );
      this.selectedSumbol = [];
    },
    selectSumbol(obj, from = null) {
      if (
        obj.sequence === this.selectedSumbol.sequence &&
        obj.start === this.selectedSumbol.start
      ) {
        return;
      }

      this.selectedSumbol = [obj];
      if (from !== 'DNA') {
        const idStart = `s-${obj.sequence}-${obj.start}`;
        VueScrollTo.scrollTo('#' + idStart, 100, optionsOutput);
      }
    },
    selectDNASeq({ sequence, index }) {
      let newItem = []
      if (index === this.mapping[this.mapping.length - 1].start &&
        sequence === this.mapping[this.mapping.length - 1].sequence) {
        newItem[0] = this.mapping[this.mapping.length - 1]
      } else {
        newItem = this.mapping.filter(item => {
          if (
            item.sequence === sequence &&
            index >= item.start &&
            index <= item.end
          ) {
            return item;
          }
        });
      }
      if (newItem.length === 0) {
        this.selectedSumbol = [
          {
            active: false,
            end: this.mapping[0]?.start - 1 || 0,
            input: '',
            endSequence: this.mapping[0]?.sequence || 0,
            start: 0
            // sequence: this.mapping[0]?.sequence
          }
        ];
        // VueScrollTo.scrollTo('#' + 's-1-56-62', 100, optionsInput);
        // this.$scrollTo('#' + `s-${this.mapping[0]?.sequence}-${this.mapping[0]?.start}-${this.mapping[0]?.end}`);
        return;
      }
      const newSelectedSumbol = [
        {
          ...newItem,
          href: `s-${newItem[0]?.sequence}-${newItem[0]?.start}-${newItem[0]?.end}`
        }
      ];
      this.$nextTick(() => {
        const idStart = newSelectedSumbol[0].href;
        console.log('newItem[0]', newItem[0])
        this.selectSumbol(newItem[0], 'DNA');
        VueScrollTo.scrollTo('#' + idStart, 0, optionsInput);
      });
    },
    async loadFalsaFile(url) {
      try {
        const file = await this.axios.get(url, {
          responseType: 'blob'
        });
        return file.data;
      } catch (e) {
        throw new Error(e.message);
      }
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
      });
    },
    getOutputArray(text) {
      const outputArray = text
        .split('>Seq')
        .filter(item => item.length)
        .map(item => {
          const sequence = item
            .split('')
            .filter(sumbol => {
              if (Number.isInteger(Number(sumbol)) === true) {
                return sumbol;
              }
            })
            .join('');

          const value = item
            .split('')
            .filter(sumbol => {
              if (Number.isInteger(Number(sumbol)) !== true) {
                return sumbol;
              }
            })
            .join('');

          return {
            sequence: Number(sequence),
            value
          };
        });

      return outputArray || [];
    },
    getMapping(data) {
      return data?.mapping.map(item => {
        return {
          ...item,
          active: false,
          href: `s-${item.sequence}-${item.start}-${item.end}`
        };
      }) || [];
    },
    back() {
      if (this.showHowItWorks) {
        this.showHowItWorks = false;
      } else {
        this.$router.push({ name: 'Home' });
      }
    },
    quantityOfSymbolsBeforeBreak(breakObj) {
      const breakIndex = this.mapping.findIndex(item => item.href === breakObj.href)
      let counter = 0
      for (let i = breakIndex - 1; i >= 0; i--) {
        if (this.mapping[i].input === '\\n') return counter
        counter++
      }
      return counter
    }
  },
  updated() {
    this.$nextTick(function() {
      var minLeft = null;
      var rows = 0;
      $('.value-wrapper span.letter-box').each(function() {
        var div = $(this);
        var left = div.offset().left;
        if (left <= minLeft || minLeft == null) {
          rows++;
          minLeft = left;
        }
      });
      if (this.rows !== rows) {
        this.rows = rows;
      }
    });
  },
  mounted() {
    this.setProcessType('INTERACTIVE DEMO');
    const jobId = this.$route.query.job_id;
    if (jobId !== undefined && jobId.length && this.textarea.length === 0) {
      (async () => {
        try {
          const response = await this.axios.get(
            VUE_APP_URL + `/api/v1/jobs/${jobId}`
          );
          const data = response.data || {};
          this.encodeStatus = 'finished';
          this.data = data;
          this.mapping = this.getMapping(data);
          this.textarea = this.mapping.map(obj => obj.input).join('') || '';
          const file = await this.loadFalsaFile(data.output_file_url);
          const text = await this.readFileAsync(file);
          this.encodeResult = await this.getOutputArray(text);
          this.amountSeq =
            this.encodeResult[this.encodeResult.length - 1]?.sequence || 0;
        } catch (e) {
          this.$vToastify.error({
            body: `Error loading document`,
            title: ' ',
            duration: 2000,
            icon: ' ',
            type: 'error'
          });
        }
      })();
    }

    bus.$on('onopen', () => {
      this.$webSocketsSend(
        JSON.stringify({ is_file: false, payload: this.textarea })
      );
    });
    bus.$on('onmessage', async event => {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        if (data.process === 0.1) {
          this.encodeResult = 'Processing...';
        } else if (data.status === 'Completed') {
          this.encodeStatus = 'finished';
          this.data = data;

          this.mapping = this.getMapping(data);
          const file = await this.loadFalsaFile(data.output_file_url);
          const text = await this.readFileAsync(file);
          // const text = `>Seq1
          // GAGAGCACTCGAGACTCATGAGTATCAGAGAGCTCTCTCTAGTCTCTCTCTCGTACTCTCTCTCTCATCTCTCTCTCTCTCTCTCTCTCTCTC
          // >Seq2
          // GCTCTCTCTCTCTCTCTCTCTCAGATAGATGAGACTGATCGTCTCTACTCTCGTCGAGACTATCGATACTACGAGACGTACTACTGTCTATCG
          // >Seq3
          // GTCTCGACAGATGAGACGACTCGTAGCTCGACAGTCGCGACGATCTAGCTGAGTCGCTACGTAGTAGTCTACGAGAGCGAG
          // `;
          this.encodeResult = await this.getOutputArray(text);

          this.amountSeq =
            this.encodeResult[this.encodeResult.length - 1]?.sequence || 0;

          this.$webSocketsDisconnect(this.connect);

          if (this._isMobile) {
            // this.$nextTick(() => this.selectSumbol(this.mapping[0]));
            if (this.$refs.contentInput) {
              // this.$set(this, 'visibleItems', []);
              const observer = new IntersectionObserver(entries => {
                // console.log('entries', entries);
                // const nextSiblingId = entries[0].target.nextSibling.id;
                // console.log('entries[0].target', entries[0]);
                // console.log('this.mapping', this.mapping);
                // const nextItem = this.mapping.find(
                //   mapping => mapping.href === nextSiblingId
                // );
                // console.log('nextItem', nextItem);
                // this.selectSumbol(nextItem);
                const newItemsVisible = new Set(this.visibleItems);
                entries.forEach(elem => {
                  if (elem.isIntersecting) {
                    const text = elem.target.id;
                    // console.log('Ping! Visible: ', text);
                    // console.log(
                    //   ' Array.from(newItemsVisible)',
                    //   Array.from(newItemsVisible).length
                    // );
                    console.log('text', text);
                    newItemsVisible.add(text);
                    // console.log('newItemsVisible.size', newItemsVisible.size);
                    if (newItemsVisible.size > 3) {
                      // console.log('newItemsVisible', newItemsVisible);
                      // // newItemsVisible.forEach(item => {
                      // //   if (item) {
                      // console.log(
                      //   'newItemsVisible Array',
                      //   Array.from(newItemsVisible)
                      // );

                      newItemsVisible.delete(Array.from(newItemsVisible)[0]);

                      // console.log('newItemsVisible2', newItemsVisible);
                      //   }
                      // });
                      // const value = Array.from(newItemsVisible)[0].value;
                      // console.log('delete', value);
                      // newItemsVisible.delete(value);
                    }
                    // const nextItem = this.mapping.find(
                    //   mapping => mapping.href === nextSiblingId
                    // );
                  }
                });
                // console.log('newVisibleItems', newItemsVisible);
                this.$set(this, 'visibleItems', Array.from(newItemsVisible));
                // console.log('this.mapping', this.mapping);
              });
              document
                .querySelectorAll('#input-block__result li')
                .forEach(elem => observer.observe(elem));
            }
          }
        }
      }
    });
  },
  destroyed() {
    this.textarea = '';
    bus.$off('onopen');
    bus.$off('onmessage');
    // this.encodedTextarea = '';
    // this.encodeResult = [];
  }
};
</script>

<style lang="scss">
.interactive-page {
  &__header {
    position: sticky;
    top: 61px;
    margin-bottom: 20px;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    flex-direction: row;
    z-index: 1;
    width: fit-content;
    margin-left: auto;
    color: #394459;

    @media (max-width: 767px) {
      top: 50px;
    }

    &-title {
      width: calc(100% - 35px);
    }

    &-icon {
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
      float: right;
      display: flex;
      cursor: pointer;

      &.enabled {
        background-image: url('../assets/question-blue-icon.svg');
      }
    }
  }
  &__container {
    margin: 0 10vw;

    @media (max-width: 767px) {
      margin: 0 10px;
    }
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;
    padding: 31px 0 62px;
    min-width: 460px;

    @media (max-width: 767px) {
      padding: 31px 16px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
      min-width: unset;
      // margin: 0 10px;
    }

    &.mobile {
      padding: 0;
      .x-mandatory {
        scroll-snap-type: x mandatory;
      }
      .container {
        // width: 270px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: scroll;
        position: relative;
      }
    }
  }

  .input-block,
  .output-block {
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    display: flex;
    flex-direction: column;

    color: #989898;

    &__title {
      text-transform: uppercase;
    }
  }

  .input-block {
    .v-counter {
      font-family: var(--primary-font-family);
    }
    .v-messages__message {
      color: var(--kern-red);
      font-family: var(--primary-font-family);
      letter-spacing: 3px;
      font-weight: 700;
    }
    .v-textarea textarea {
      line-height: 60px !important;
      // letter-spacing: 22px;
      letter-spacing: 11px;
      resize: none;
    }

    ::v-deep .v-input__slot {
      margin-bottom: 5px;
      textarea {
        resize: none;
        line-height: 60px;
      }
    }

    .w-100 {
      width: 100%;
    }

    &__preview {
      background: #f1f1f1;
      border-radius: 4px;
      margin-top: 20px;
      padding: 20px;
      max-height: 80px;
      overflow: auto;
    }

    &__textarea {
      min-height: 149px;
      flex-wrap: wrap;
      // z-index: -1;

      background: #ffffff;
      border: 3px solid #8a8a8a;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 13px 10px 10px 38px;

      font-family: DM Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 60px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1em;

      color: #6a6a6a;
      margin-top: 10px;

      @media (max-width: 567px) {
        padding: 10px;
      }
    }

    &__result {
      flex-wrap: wrap;
      overflow-y: auto;
      margin-left: 0.1em;
      font-family: DM Mono;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 60px;
      min-height: 35px;
      padding: 20px;
      padding-left: 0;

      display: flex;
      align-items: center;
      letter-spacing: 0.1em;

      color: #6a6a6a;

      background: #f1f1f1;
      border: 1px solid #8a8a8a;
      box-sizing: border-box;
      border-radius: 4px;
      margin: 14px 0 0;
      padding-left: 29px;

      cursor: url('../assets/magnifying-glass-cursor-x2.png') 10 10, auto;
      cursor: url('../assets/magnifying-glass-cursor.svg') 10 10, auto;
      cursor: -webkit-image-set(
            url('../assets/magnifying-glass-cursor-x1.png') 1x,
            url('../assets/magnifying-glass-cursor-x2.png') 2x
          )
          10 10,
        auto; /* Webkit */

      &.mobile {
        border: none;
        padding-left: 14px;
        height: 159px;
        overflow-y: hidden;
      }
      .line {
        display: flex;
        flex-wrap: wrap;
      }
      .content {
        display: flex;

        &.mobile {
          // height: 300px;
          display: flex;
          min-width: 100%;

          &.line {
            height: 119px;
            display: flex;
            flex-wrap: nowrap;

            .symbol-wrapper {
              list-style-type: none;
              // height: 220px;
              height: 119px;
              // width: 90px;
              min-width: 36%;
              scroll-snap-align: start;
              scroll-snap-stop: always;
              display: inline-block;
              // height: 83vh;
              border-radius: 3px;
              font-size: 0;
              margin-right: 3px;
              flex-wrap: nowrap;

              .symbol-box {
                overflow-y: hidden;
                height: inherit;
                // width: 90px;
                min-width: 100%;
                position: relative;
                justify-content: center;
                align-items: center;
                display: flex;
                // list-style-type: none;

                .symbol {
                  position: absolute;
                  height: inherit;
                  font-size: 144px;
                  line-height: 60px;
                  // width: 90px;
                  min-width: 100%;
                }
              }
            }
          }
        }
      }
      // .line {
      //   flex-wrap: wrap;
      //   display: inline-flex;

      //   &.mobile {
      //     flex-wrap: nowrap;
      //     overflow-y: auto;
      //     overflow-x: none;
      //   }
      // }
      .symbol-wrapper {
        width: 38px;
        &.line-break {
          .symbol-box {
            width: 38px;
          }
        }
      }
      .symbol-box {
        padding: 0 1px;
        height: 56px;
        line-height: 52px;
        pointer-events: none;
        border: 2px solid transparent;

        &.active {
          background: #ffffff;
          border: 2px solid #6a6a6a;
          box-sizing: border-box;
          border-radius: 5px;
          height: 56px;
          line-height: 52px;
          width: inherit;
          color: #6a6a6a;
          letter-spacing: 0;
          text-align: center;
          justify-content: center;
        }
      }
      .symbol-box:hover {
        background: #ffffff;
        border: 2px solid #6a6a6a;
        box-sizing: border-box;
        color: #6a6a6a;
        height: 56px;
        line-height: 52px;
        border-radius: 5px;
        letter-spacing: 0;
      }

      .symbol {
        pointer-events: auto;
        font-family: DM Mono;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 60px;
        // padding: 0 1px;

        display: flex;
        align-items: center;
        text-align: center;
        // min-width: 16px;
        min-height: 35px;
        letter-spacing: 0;
        height: 56px;
        line-height: 52px;
        // width: 38px;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 5px;
        margin: 0 0 0 -3px;
        min-width: 38px;

        // &.active {
        //   background: #ffffff;
        //   border: 2px solid #6a6a6a;
        //   box-sizing: border-box;
        //   border-radius: 5px;
        //   height: 56px;
        //   // min-width: 16px;
        //   line-height: 52px;
        //   color: #6a6a6a;
        //   letter-spacing: 0;
        //   text-align: center;
        //   justify-content: center;
        // }
      }
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;

      &-wrapper {
        display: flex;
        background: #555555;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 0px 8px;
        height: 70px;
        padding-left: 21px;
        padding-right: 14px;
        align-items: center;

        @media (max-width: 520px) {
          height: 100%;
        }
      }

      &-general {
        display: flex;

        @media (max-width: 520px) {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }
      }

      .dialog-box-wrapper {
        display: flex;
        @media (max-width: 520px) {
          width: 100%;
          justify-content: flex-end;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .v-btn {
          min-width: 40px;
          width: 40px;
          padding: 0;
          height: 40px !important;
          background: #727272 !important;

          &__content {
            height: 40px;
          }
        }
      }
      .v-btn {
        background: #dbdbdb;

        height: 42px;

        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;

        color: #ffffff;
        box-shadow: none;
        border-radius: 6px;

        &.enabled {
          color: #394459;
          background: #fff;
        }

        @media (max-width: 400px) {
          margin: 0;
        }
      }

      .actions {
        &-encode {
          background: var(--button-red);
        }

        &-proseccing {
          background: #dbdbdb;
          color: #656565;
        }

        &-find {
          background: #dbdbdb;

          &.enabled {
            .i-icon {
              background-image: url('../assets/i-enabled.svg');
            }
            .more-icon {
              background-image: url('../assets/more-icon-enabled.svg');
            }
          }
        }

        &-cancel {
          background: #fff;
          color: #394459;
          margin-left: 20px;

          @media (max-width: 420px) {
            width: 42px;
            height: 42px;
            padding: 0;
            min-width: 42px;
            font-size: 26px;
          }
        }
      }

      .v-btn--disabled {
        .encode-icon {
          background-image: url('../assets/encode-icon-unactive.svg');
        }
      }

      .encode-icon {
        background-image: url('../assets/encode-icon.svg');
        background-repeat: no-repeat;
        width: 28px;
        height: 28px;
        margin-right: 8px;

        &.unactive {
          background-image: url('../assets/encode-icon.svg');
        }
      }

      .proseccing-icon {
        background-image: url('../assets/proseccing-icon.svg');
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        animation: spin 5s linear infinite;
        margin-right: 10px;
      }

      .edit-icon {
        background-image: url('../assets/edit-icon.svg');
        background-repeat: no-repeat;
        width: 29px;
        height: 27px;
        margin-right: 8px;
      }

      .i-icon {
        background-image: url('../assets/i.svg');
        background-repeat: no-repeat;
        height: 20px;
        width: 10.4px;
      }

      .find-icon {
        background-image: url('../assets/i.svg');
        background-repeat: no-repeat;
        height: 20px;
        width: 10.4px;
      }

      .more-icon {
        background-image: url('../assets/more-icon.svg');
        background-repeat: no-repeat;
        width: 26.2px;
        height: 6.05px;
      }
    }
  }

  .output-block {
    margin-top: 61px;

    &__result {
      cursor: url('../assets/magnifying-glass-cursor-x1.5.png') 10 10, auto;
      cursor: url('../assets/magnifying-glass-cursor.svg') 10 10, auto;
      cursor: -webkit-image-set(
            url('../assets/magnifying-glass-cursor-x1.png') 1x,
            url('../assets/magnifying-glass-cursor-x2.png') 2x
          )
          10 10,
        auto; /* Webkit */
      // cursor: url('../assets/magnifying-glass-cursor.png') 10 10, auto;
      // cursor: url('../assets/magnifying-glass-cursor.svg') 10 10, auto;
      // cursor: url(/img/magnifying-glass-cursor_1.gif) 10 10, auto;

      flex-wrap: wrap;
      margin-top: 12px;
      background: #fcfcfc;
      border: 1px solid #8a8a8a;
      box-sizing: border-box;
      border-radius: 4px;
      height: 290px;
      overflow-y: auto;
      padding-top: 25px;
      padding-bottom: 25px;

      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;

      text-align: justify;

      color: #000000;

      .number {
        flex-direction: column;
        margin-left: 29px;
        min-width: 26px;
        min-height: 40px;
        margin-right: 29px;

        font-size: 16px;
        line-height: 19px;

        text-align: center;

        color: #8a8a8a;

        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 567px) {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      &.mobile {
        height: 80px;
        overflow-y: hidden;
      }
      .content {
        display: flex;

        .value-wrapper {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
        }
      }

      &.mobile {
        // height: 80px;
        .value-wrapper {
          height: 40px;
          flex-wrap: nowrap;
          // background: red;
          display: flex;
          // overflow-x: scroll;
          // scroll-padding: 0 50%;
          // scroll-padding-right: 3px;
          // scroll-snap-type: x mandatory;

          .letter-box {
            scroll-snap-align: start;
            scroll-snap-stop: always;
            font-size: 0;
          }
        }

        .value-wrapper {
          display: flex;
          // flex-wrap: wrap;
        }

        .letter-wrapper {
          width: 37px;
          display: flex;
          align-items: center;
          padding: 4px 0;
        }
      }

      .letter-box {
        // cursor: url('../assets/magnifying-glass-cursor.svg') 4 12, auto;
        // cursor: url(/img/magnifying-glass-cursor_1.gif) 10 10, auto;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        align-items: center;
        border: 0px solid transparent;

        &.active {
          background: rgba(94, 94, 94, 0.7);
          border-top: 1px solid rgba(28, 23, 23, 0.7);
          border-bottom: 1px solid rgba(28, 23, 23, 0.7);
          box-sizing: border-box;
          padding: 0;
          height: 40px;
          transition: all 0.3s;
          // margin-bottom: 2px;

          &.highlight .wrapper {
            border: 1px solid rgba(28, 23, 23, 0.7);
            box-sizing: border-box;
            border-radius: 5px;
            padding: 4px;
          }

          .wrapper:hover .letter {
            border: 2px solid #394459;
            border-radius: 3px;
          }

          &.first {
            border-radius: 5px 0px 0px 5px;
            border-left: 1px solid rgba(28, 23, 23, 0.7);
            transition: all 0.3s;
          }
          &.last {
            border-radius: 0px 5px 5px 0px;
            border-right: 1px solid rgba(28, 23, 23, 0.7);
            transition: all 0.3s;
          }
          &.finish {
            border-radius: 5px;
            border-right: 1px solid rgba(28, 23, 23, 0.7);
            transition: all 0.3s;
          }
        }
      }
      .letter {
        border-radius: 3px;
        width: 30px;
        height: 30px;
        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;

        color: #394458;
        border: 1px solid rgba(0, 0, 0, 0.36);
        border-radius: 3px;

        &.a {
          background: #dcedc8;
        }
        &.t {
          background: #ffccbc;
        }
        &.c {
          background: #e1f5fe;
        }
        &.g {
          background: #ffecb3;
        }
      }
      // }
    }
  }

  .row .justify-center {
    justify-content: center;
  }
}

.row .justify-center {
  justify-content: center;
}
</style>

<style lang="scss">
.interactive-page {
  .v-btn.actions {
    &-encode {
      background: var(--button-red);
      &.v-btn--disabled {
        color: #858585 !important;
      }
    }

    &-find,
    &-more {
      background-color: rgba(0, 0, 0, 0.26) !important;
      height: 42px !important;
      width: 42px;
      // margin-right: 20px;
      height: 42px;

      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
      margin-left: 20px;
      box-shadow: none;
      border-radius: 6px;
      cursor: default;

      &.enabled {
        color: #394459;
        cursor: pointer;
        background-color: #fff !important;
      }
    }
  }
}

.v-dialog.find-dialog {
  width: 529px;
  height: 201px;
}

.index {
  display: flex;
  width: 100px;
}

.line-break__tooltip {
  width: 100px !important;
  text-align: center;
}
</style>
