<template>
  <div class="extra-menu__container">
    <div class="close-icon" @click="$emit('close')"></div>
    <div class="extra-menu__container-info">
      <div class="input">
        <div class="icon">
          <div class="your-data-icon"></div>
        </div>
        <span class="text"><span class="bold">Input</span>{{ input }}</span>
      </div>
      <div class="direction-left-icon"></div>
      <div class="output">
        <div class="icon">
          <div class="dna-seq-icon"></div>
        </div>
        <span class="text"> <span class="bold">Output</span>{{ output }}</span>
      </div>
    </div>
    <div class="extra-menu__container-actions">
      <div class="title">ACTIONS</div>
      <div class="wrapper">
        <DialogBox
          :parentClassBtn="['button']"
          :disabled="showDownloadOutput"
          @click="showDownloadOutput = true"
          :width="704"
        >
          <template #activator>
            <div class="icon">
              <div class="download-output-icon"></div>
            </div>
            <span class="text">download output</span>
          </template>

          <template #title>
            <div class="icon">
              <div class="download-output-blue-icon"></div>
            </div>
            <span class="text">Download Output</span>
          </template>

          <template #description>
            <div class="text">
              You can download the output DNA sequences will be saved in FASTA
              file format, or a CSV file compatible with major DNA synthesizer
              vendors such as Twist Bioscience.
            </div>
          </template>
          <template #actions>
            <div class="actions">
              <v-btn class="mr-20"
                ><a :href="outputFileUrl">DNA Sequences</a></v-btn
              >
              <v-btn disabled>vendor-compatible file</v-btn>
            </div>
          </template>
        </DialogBox>

        <DialogBox
          :width="704"
          :parentClassDialog="['dialog-share']"
          :parentClassBtn="['button']"
          :disabled="showSharePage"
          @click="showSharePage = true"
        >
          <template #activator>
            <div class="icon">
              <div class="share-page-icon"></div>
            </div>
            <span class="text">share this page</span>
          </template>

          <template #title>
            <div class="icon">
              <div class="check-pricing-blue-icon"></div>
            </div>
            <div class="text share-box">
              <div>Share This Page</div>
              <div class="sub-title">
                with the entered input text and encoded DNA sequences
              </div>
            </div>
          </template>

          <template #description>
            <div class="text">
              <span
                ><span ref="link" class="link">{{ shareLink }}</span></span
              >
            </div>
          </template>
          <template #actions>
            <div class="actions flex-end">
              <v-btn
                :href="
                  `mailto:?subject=Interactive Demo of Olli’s&body=Hello, I was trying out an Interactive Demo of Olli’s DNA encoder, and this is what I got. Click here (${shareLink}) to check it out.`
                "
                target="_blank"
                class="mr-20 w-150"
              >
                email
              </v-btn>
              <v-btn class="w-150" @click="copyUrl">Copy url</v-btn>
            </div>
          </template>
        </DialogBox>
      </div>
    </div>
  </div>
</template>

<script>
import DialogBox from './DialogBox.vue';

export default {
  name: 'ExtraMenu',
  components: { DialogBox },
  props: {
    shareLink: {
      type: String,
      default: ''
    },
    outputFileUrl: {
      type: String,
      default: ''
    },
    input: {
      type: String,
      default: ''
    },
    output: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: '$0'
    }
  },
  data() {
    return {
      showDownloadOutput: false,
      showSharePage: false,
    };
  },
  methods: {
    copyUrl() {
      const link = this.$refs.link.innerText || '';
      navigator.clipboard.writeText(link);
      this.$vToastify.info({
        body: 'Copied the text',
        title: ' ',
        duration: 1000,
        icon: ' ',
        type: 'info'
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.extra-menu__container {
  background: rgba(255, 255, 255, 0.97);
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;

  border-radius: 10px;
  padding: 94px 112px 62px;

  .close-icon {
    background-image: url('../assets/close-icon.svg');
    width: 30px;
    height: 30px;
    right: 31px;
    position: absolute;
    top: 23px;
    cursor: pointer;
  }

  &-info {
    width: 567px;
    margin-right: 90px;
    margin-top: 20px;

    .input,
    .output {
      background: #f3fcff;
      border: 3px solid #394459;
      box-sizing: border-box;
      border-radius: 50px;
      width: 547px;
      height: 120px;
      display: flex;
      align-items: center;
      padding-left: 33px;

      .your-data-icon {
        background-image: url('../assets/your-data-75-icon.svg');
        width: 75px;
        height: 75px;
      }

      .dna-seq-icon {
        background-image: url('../assets/dna-seq-75-icon.svg');
        width: 75px;
        height: 75px;
      }

      .text {
        margin-left: 17px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 29px;
        line-height: 36px;

        color: #394459;

        mix-blend-mode: normal;

        .bold {
          font-weight: bold;
          margin-right: 6px;
        }
      }
    }

    .output {
      background: #fffcf3;
    }

    .direction-left-icon {
      background-image: url('../assets/arrow-icon.svg');
      width: 70px;
      height: 69px;
      margin: 33px auto;
    }
  }

  &-actions {
    width: 388px;
    height: 395px;
    background: #f8f8f8;
    border: 3px solid #394459;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 32px 44px;

    .title {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 29px;
      line-height: 36px;
      display: flex;
      align-items: center;

      color: #394459;
    }

    .wrapper {
      margin-left: 16px;

      .button {
        margin-top: 30px;
        width: 240px;
        height: 60px;

        background: #394458 !important;
        padding-left: 19px;
        justify-content: start;

        .text {
          letter-spacing: 0;
          border-radius: 6px;
          font-family: Karla;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #ffffff;
        }

        .icon {
          width: 31.11px;
          height: 31.11px;
          margin-right: 9px;

          .download-output-icon {
            background-image: url('../assets/download-output-icon.svg');
            height: 100%;
          }

          .share-page-icon {
            background-image: url('../assets/share-page-icon.svg');
            height: 100%;
          }

          .check-pricing-icon {
            width: 30px;
            height: 30px;
            background-image: url('../assets/check-pricing-icon.svg');
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.v-btn.button {
  margin-top: 30px !important;
  width: 240px !important;
  height: 60px !important;

  background: #394458 !important;
  padding-left: 19px !important;
  justify-content: start !important;
}

a[href^='mailto:'] {
  font-size: 16px;
  line-height: 32px;
  color: #000000;
}

.v-card {
  &.dialog-content {
    display: flex;
    flex-direction: column;

    .v-card {
      &__title {
        .icon {
          width: 60px;
          height: 60px;
          margin-right: 33px;
          display: inline-block;

          .download-output-blue-icon {
            background-image: url('../assets/download-output-blue-icon.svg');
            height: 100%;
          }

          .check-pricing-blue-icon {
            width: 53px;
            height: 53px;
            background-image: url('../assets/check-pricing-blue-icon.svg');
          }

          .check-pricing-blue-icon {
            background-image: url('../assets/check-pricing-blue-icon.svg');
            height: 60px;
            width: 60px;
          }
        }
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;

          color: #394459;

          &.share-box {
            width: 85%;
            display: block;
            margin-left: -8px;

            .sub-title {
              font-weight: normal;
              font-size: 20px;
              line-height: 25px;
              display: flex;
              align-items: center;

              color: #394458;
            }
          }
        }
      }
      &__text {
        .text {
          font-family: Source Sans Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 25px;

          color: #394459;

          .bold {
            font-weight: bold;
          }
        }
      }
    }
  }
  &.dialog-share {
    .v-card__text {
      background: #f3f3f3;
      border: 2px solid #394459;
      box-sizing: border-box;
      border-radius: 4px;

      .text {
        padding: 7px 16px;
      }

      span.link {
        padding: 4px 10px;
        background: #394459;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;

        color: #ffffff;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;

    &.flex-end {
      justify-content: flex-end;
    }
    .v-btn {
      height: 45px;
      margin-top: 38px;

      background: #394459;
      border-radius: 6px;

      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;

      a {
        font-family: Karla;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;

        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;

        color: #ffffff;
        text-decoration: none;
      }

      &.mr-20 {
        margin-right: 20px;
      }

      &.w-150 {
        width: 150px;
      }
    }
  }
}
</style>
