<template>
  <v-dialog :width="width" v-model="dialog" persistent :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" :class="parentClassBtn">
        <slot name="activator">
          Open Dialog
        </slot>
      </v-btn>
    </template>
    <div class="close-icon" @click="dialog = false"></div>
    <v-card :class="['dialog-content', ...parentClassDialog]">
      <v-card-title>
        <slot name="title">
          <div class="noun-looking"></div>
          {{ title }}
        </slot>
      </v-card-title>
      <v-card-text>
        <slot name="description">
          {{ description }}
        </slot>
      </v-card-text>
      <slot name="actions"></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogBox',
  props: {
    width: {
      type: Number,
      default: 350
    },
    parentClassDialog: {
      type: Array,
      default: () => []
    },
    parentClassBtn: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      title: 'How was the text encoded?',
      description:
        'Mouse over input text to find the corresponding DNA sequences used to encode the letter, and vice versa.'
    };
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  padding: 20px;
  position: relative;
  box-shadow: none;

  .close-icon {
    background-image: url('../assets/dialog-close-btn.svg');
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    z-index: 111;
    right: 2px;
    top: 2px;
    cursor: pointer;
  }

  ::v-deep .v-card {
    padding: 41px 0 51px 45px;
  }

  .v-card {
    border: 3px solid #394459;
    box-sizing: border-box;
    border-radius: 10px;
    font-family: Source Sans Pro;
    padding: 41px 45px 51px;

    &__title {
      font-family: Source Sans Pro;
      padding: 0 !important;
      font-style: normal;
      font-weight: bold !important;
      font-size: 24px !important;
      line-height: 30px;
      display: flex;
      align-items: center;

      color: #394458 !important;

      .noun-looking {
        background-image: url('../assets/noun-looking.svg');
        background-repeat: no-repeat;
        width: 48px;
        height: 47px;
        margin-right: 23px;
      }
    }

    &__text {
      margin-top: 21px;
      font-family: Source Sans Pro;
      padding: 0 !important;
      font-style: normal;
      font-weight: normal;
      font-size: 20px !important;
      line-height: 25px;
      display: flex;
      align-items: center;

      color: #394458 !important;
    }
  }
}

.v-dialog__content {
  transition: none;
  &--active {
    background: rgba(196, 196, 196, 0.8);
    backdrop-filter: blur(4px);
  }
}
.row.justify-center {
  justify-content: center;
  width: 42px;
  height: 42px;
}
</style>

<style lang="scss">
.v-overlay {
  margin-top: 61px;
}
</style>
