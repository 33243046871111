<template>
  <div
    :class="[
      'output-block__result',
      { 'container x-scroll x-mandatory mobile': _isMobile }
    ]"
    id="output-block__result"
    ref="contentOutput"
  >
    <!-- <div class="d-flex"> -->
    <div :class="['content', { mobile: _isMobile }]" v-if="!_isMobile">
      <div class="number-wrapper">
        <template v-for="row in rows" class="d-flex">
          <div class="number" :key="row">
            <span v-if="row === 1 || row % 5 === 0">{{ row }}</span>
          </div>
        </template>
      </div>
      <div class="value-wrapper">
        <template v-for="row of encodeResult">
          <span
            v-for="(letter, index) of filteredRow(row.value, row.sequence)"
            :key="`${row.sequence}-${letter}-${index}`"
            @mouseover="selectDNASeq(row.sequence - 1, letter, index)"
            :class="['letter-box', activeSumbol(row.sequence - 1, index)]"
            :id="`s-${row.sequence - 1}-${index}`"
          >
            <span
              v-if="
                !activeSumbol(row.sequence - 1, index).includes('highlight') &&
                  !activeSumbol(row.sequence - 1, index).includes('metadata')
              "
              class="wrapper"
            >
              <span :class="['letter', letter.toLowerCase()]">
                {{ letter }}
              </span>
            </span>
          </span>
        </template>
      </div>
    </div>
    <template v-else>
      <!-- <div v-else class="content mobile" ref="contentBox"> -->
      <ul class="value-wrapper">
        <template v-for="row of encodeResult">
          <li
            v-for="(letter, index) of filteredRow(row.value, row.sequence)"
            :key="`${row.sequence}-${letter}-${index}`"
            @mouseover="selectDNASeq(row.sequence - 1, letter, index)"
            :class="['letter-box', activeSumbol(row.sequence - 1, index)]"
            :id="`s-${row.sequence - 1}-${index}`"
          >
            <span
              v-if="
                !activeSumbol(row.sequence - 1, index).includes('highlight') &&
                  !activeSumbol(row.sequence - 1, index).includes('metadata')
              "
              class="wrapper"
            >
              <span :class="['letter', letter.toLowerCase()]">
                {{ letter }}
              </span>
            </span>
          </li>
        </template>
      </ul>
    </template>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
  name: 'OutputResult',
  props: {
    rows: {
      type: Number,
      default: 0
    },
    encodeResult: {
      type: Array,
      default: () => []
    },
    selectedSumbol: {
      type: Array,
      default: () => []
    },
    mapping: {
      props: Array,
      default: () => []
    }
  },
  data() {
    return {
      item: 0,
      currentIndex: null,
      visibleItemsOutput: []
    };
  },
  computed: {
    _isMobile() {
      return isMobile;
    }
  },
  mounted() {
    const observer = new IntersectionObserver(entries => {
      console.log('gff');
      const visibleItemsOutput = new Set(this.visibleItemsOutput);
      entries.forEach(elem => {
        if (elem.isIntersecting) {
          const text = elem.target.id;
          console.log('Ping! Visible: ', text);

          console.log('text', text);
          visibleItemsOutput.add(text);
          // console.log('newItemsVisible.size', newItemsVisible.size);
          if (visibleItemsOutput.size > 3) {
            // console.log('newItemsVisible', newItemsVisible);
            // // newItemsVisible.forEach(item => {
            // //   if (item) {
            // console.log(
            //   'newItemsVisible Array',
            //   Array.from(newItemsVisible)
            // );

            visibleItemsOutput.delete(Array.from(visibleItemsOutput)[0]);

            // console.log('newItemsVisible2', newItemsVisible);
            //   }
            // });
            // const value = Array.from(newItemsVisible)[0].value;
            // console.log('delete', value);
            // newItemsVisible.delete(value);
          }
          // const nextItem = this.mapping.find(
          //   mapping => mapping.href === nextSiblingId
          // );
        }
      });
      console.log('visibleItemsOutput', visibleItemsOutput);
      this.$set(this, 'visibleItemsOutput', Array.from(visibleItemsOutput));
      // console.log('this.mapping', this.mapping);
    });
    console.log(document.querySelectorAll('#output-block__result li'));
    document
      .querySelectorAll('#output-block__result li')
      .forEach(elem => observer.observe(elem));
  },
  methods: {
    // slice metadata part of each row
    filteredRow(letters) {
      return letters.slice(this.mapping[0]?.start)
    },
    selectDNASeq(sequence, letter, index) {
      index += this.mapping[0]?.start
      this.$emit('selectDNASeq', { sequence, letter, index });
      this.currentIndex = index;
    },
    activeSumbol(sequence, index = this.currentIndex) {
      const { selectedSumbol } = this;
      index += this.mapping[0]?.start
      if (selectedSumbol.length < 2) {
        const sumbol = selectedSumbol[0] || {};
        if (sumbol && sumbol.endSequence !== undefined) {
          if (
            (sumbol.endSequence > sequence && index >= sumbol.start) ||
            (sumbol.endSequence === sequence &&
              index >= sumbol.start &&
              index <= sumbol.end)
          ) {
            const first =
              sumbol.start === index && sequence === 0 ? 'first' : '';
            const last =
              sumbol.end === index && sumbol.endSequence === sequence
                ? 'last'
                : '';
            return `active metadata ${first} ${last}`;
          } else {
            return '';
          }
        } else {
          if (
            sumbol.sequence == sequence &&
            index >= sumbol.start &&
            index <= sumbol.end
          ) {
            const first = sumbol.start === index ? 'first' : '';
            const last = sumbol.end === index ? 'last' : '';
            return `active ${first} ${last}`;
          } else {
            return '';
          }
        }
      } else {
        if (
          selectedSumbol[0].sequence == sequence &&
          index >= selectedSumbol[0].start &&
          index <= selectedSumbol[1].end
        ) {
          const first = selectedSumbol[0].start === index ? 'first' : '';
          const last = selectedSumbol[1].end === index ? 'last' : '';
          const highlight =
            selectedSumbol[0].end === index && selectedSumbol[1].start === index
              ? 'highlight'
              : '';
          return `active ${highlight} ${first} ${last}`;
        } else {
          return '';
        }
      }
    }
  },
  watch: {
    selectedSumbol: function(newValue) {
      this.activeSumbol(newValue);
    },
    visibleItemsOutput: function(newValue) {
      if (newValue.length > 0) {
        const newItem = this.mapping.find(
          mapping => mapping.href === newValue[0]
        );
        if (newItem !== undefined) {
          this.selectDNASeq({
            sequence: newItem.sequence,
            index: newItem.index
          });
        }
      }
    }
  }
};
</script>

<style></style>
