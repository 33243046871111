<template>
  <div class="how-it-works">
    <div class="wrapper-close">
      <div class="close-icon" @click="$emit('close')"></div>
    </div>
    <div class="how-it-works__container">
      <div class="title">How it works</div>
      <div class="wrapper-steps">
        <div class="step-wrapper">
          <div class="step-box">
            <div class="wrapper-icon">
              <div class="icon-box">
                <div class="your-data-icon icon"></div>
              </div>
              <div class="title">your data</div>
            </div>
          </div>
          <div class="direction-box">
            <div class="direction-left-icon"></div>
          </div>
        </div>
        <!-- <div class="direction-left-icon"></div> -->
        <div class="step-wrapper">
          <div class="step-box">
            <div class="wrapper-icon">
              <div class="icon-box">
                <div class="our-codec-icon icon"></div>
              </div>
              <div class="title">our codec</div>
            </div>
          </div>
          <div class="direction-box">
            <div class="direction-left-icon"></div>
          </div>
        </div>

        <!-- <div class="direction-left-icon"></div> -->
        <div class="step-wrapper">
          <div class="step-box">
            <div class="wrapper-icon">
              <div class="icon-box last">
                <div class="dna-seq-icon icon"></div>
              </div>
              <div class="title">dna sequences</div>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <span
          >With our proprietary software codec,
          <span class="bold">Olli</span> enables you to encode any digital data
          such as text and images into DNA sequences. Then, you can decode these
          DNA sequences back to their original digital data. <br />
          <br />
          You can then synthesize these sequences into actual physical copies of
          the DNA strands. One ounce of DNA strands can contain a [billion
          trillion] GB of data.
          <br />
          <br />
        </span>

        <span>
          In our <span class="bold">Interactive Demo</span>, you can
          <span class="red"
            >mouse over the DNA sequences or the letters to see their encoding
            mapping.</span
          >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
};
</script>

<style lang="scss" scoped>
.how-it-works {
  position: relative;
  margin-top: -20px;

  .wrapper-close {
    height: 70px;
    width: 70px;
    position: sticky;
    z-index: 333;
    top: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #555555;
    border-radius: 0px 0px 0px 8px;
    float: right;
    right: 0;

    @media (max-width: 768px) {
      height: 60px;
      width: 60px;
      top: 50px;
    }

    .close-icon {
      background-image: url('../assets/close-icon.svg');
      width: 30px;
      height: 30px;

      cursor: pointer;

      @media (max-width: 768px) {
        background-image: url('../assets/close-icon-25.svg');
        height: 25px;
        width: 25px;
      }
    }
  }

  &__container {
    background: rgba(255, 255, 255, 0.97);
    // border: 1px solid #d4d4d4;
    box-sizing: border-box;
    backdrop-filter: blur(10px);

    border-radius: 10px;
    // padding: 50px 112px 62px;
    padding: 50px 0 62px;

    margin: 0 10vw;

    @media (max-width: 768px) {
      margin: 0 10px;
      padding: 20px 0;
    }

    // .close-icon {
    //   background-image: url('../assets/close-icon.svg');
    //   width: 30px;
    //   height: 30px;
    //   right: 31px;
    //   position: absolute;
    //   top: 23px;
    //   cursor: pointer;
    // }

    .title {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;

      text-align: center;
      text-transform: uppercase;

      color: #394459;

      @media (max-width: 768px) {
        text-align: start;
        font-size: 24px;
        line-height: 28px;
      }
    }

    .description {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 150%;
      margin-top: 62px;

      text-align: justify;

      color: #000000;
      font-size: 22px;
      line-height: 150%;

      text-align: justify;

      @media (max-width: 768px) {
        margin-top: 30px;
      }

      .red {
        font-weight: bold;
        font-size: 22px;
        line-height: 150%;
        text-align: justify;
        color: #f1505f;
      }

      .bold {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 150%;
        /* or 33px */

        text-align: justify;
      }
    }

    .wrapper-steps {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 66px 0 0;

      @media (max-width: 768px) {
        flex-direction: column;
        margin: 26px 0 0;
      }
    }
    .step-wrapper {
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }
    }
    .step-box {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      justify-self: center;
      flex-direction: column;
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      align-items: center;

      color: #394459;

      .title {
        text-align: start;
        font-size: 24px;
        line-height: 28px;

        @media (max-width: 768px) {
          margin-left: 17px;
          // margin-top: 29px;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .wrapper-icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: row;
        }
      }

      @media (max-width: 768px) {
        flex-direction: row;
        width: 100%;
      }
    }

    .icon-box {
      width: 130px;
      height: 148.37px;

      background: #f3f7ff;
      border: 3px solid #394459;
      box-sizing: border-box;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 25px;
      // margin-bottom: 25px;

      @media (max-width: 768px) {
        // margin: 0 17px 0 0;
        width: 80px;
        height: 80px;
        margin: 0 0 15px;
      }

      &.last {
        background: #fffcf3;
      }

      .your-data-icon {
        background-image: url('../assets/your-data-icon.svg');
        width: 65px;
        height: 65px;
      }

      .our-codec-icon {
        background-image: url('../assets/our-codec-icon.svg');
        width: 65px;
        height: 65px;
      }

      .dna-seq-icon {
        background-image: url('../assets/dna-seq-icon.svg');
        width: 104px;
        height: 104px;

        @media (max-width: 768px) {
          width: 80px;
          height: 80px;
          background-position: center;
        }
      }
    }

    .direction-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 148.37px;

      @media (max-width: 768px) {
        width: 80px;
        height: 48px;
      }
    }

    .direction-left-icon {
      background-image: url('../assets/direction-left-icon.svg');
      width: 60px;
      height: 60px;
      // margin: -30px auto 0;
      margin: 0 70px;

      @media (max-width: 1023px) {
        margin: 0 30px;
      }
      @media (max-width: 890px) {
        margin: 0 5px;
      }

      @media (max-width: 768px) {
        background-image: url('../assets/direction-bottom-icon.svg');
        width: 30px;
        height: 30px;
        margin-bottom: 9px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.back-button__container {
  padding: 0 112px;
  margin: 0;
}
</style>
